import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                open: 'open',
            }
        }
    }

    init() {

    }

    open(e) {
        const index = e.currentTarget.getAttribute('data-index');
        const items = this.$('item');
        items.forEach(element => element.classList.remove('is-active'));
        const opens = this.$('open');
        opens.forEach(element => element.classList.remove('is-active'));
        e.currentTarget.classList.add('is-active');
        const items2 = document.querySelectorAll('.c-widget-menu_item.is-active [data-index="'+index+'"]');
        items2.forEach(element => element.classList.add('is-active'));

        this.call('update', false, 'Scroll'); // update Locomotive Scroll
    }
}
