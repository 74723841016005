import { module } from 'modujs';
import 'select2'; // globally assign select2 fn to $ element
import 'select2/dist/css/select2.css';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                close: 'close',
                open: 'open',
                select: 'select',
                delete: 'delete',
                //reset: 'reset',
                submit: 'submit',
                update: 'update',
                pagination: 'pagination',
                submitCommune: 'submitCommune',
                closeCart: 'closeCart',
                checkCartMenu: 'checkCartMenu',
                deleteCartDay: 'deleteCartDay',
            },
            change: {
                //select: 'select',
                qte: 'qte',
                changeCommune: 'submitCommune',
            }
        }

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        let select2 = $('.select2').select2({
            placeholder: 'Votre commune',
            language: 'fr',
        });
        let $this = this;

        select2.on("change", function (e) { $this.submitCommune(e) });
    }

    open() {
        const html = document.querySelector('html');
        html.classList.add('has-menuOpen');
    }

    close() {
        const html = document.querySelector('html');
        html.classList.remove('has-menuOpen');
    }

    select(e) {
        const target = e.currentTarget;
        const btn = this.parent('btn', target);
        const row = this.parent('row', target);
        const btns = row.querySelectorAll('.c-widget-menu_menu-btn');
        btns.forEach(element => element.classList.remove('is-active'));
        btn.classList.add('is-active');
    }

    delete(e) {
        const target = e.currentTarget;
        const container = jQuery(target).parents('.menu-widget-item');
        const form = jQuery(target).parent('form');
        const main_form_container = jQuery(target).parents('.c-tab_item').find('form');

        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function( response ){
                // container.remove();

                // $(main_form_container).find('.default').removeClass('is-active');
                // $(main_form_container)[0].reset();

                
                // $(main_form_container).parents('.c-widget-menu_body').find('.c-tab_head .c-tab_item.is-active .nb').text(response.count);
                // if(response.count <= 0) {
                //     $(main_form_container).parents('.c-widget-menu_body').find('.c-tab_head .c-tab_item.is-active .nb').removeClass('is-show');
                // }

                location.reload();
            },
        });
    }

    /*select(e) {
      const target = e.currentTarget;
      const btn = this.parent('btn', target);
      const row = this.parent('row', target);
      const btns = row.querySelectorAll('.c-widget-menu_menu-btn');
      btns.forEach(element => element.classList.remove('is-active'));
      btn.classList.add('is-active');

      const form = this.parent('form', target);
      const menu_id = $(form).find('input[name="menu_id"]').val();

      jQuery.ajax({
        url: this.ajaxurl,
        type: 'POST',
        data: jQuery(form).serialize(),
        success: function( response ){
          $('#'+response.menu_id).remove();
          $(form).parent('.c-tab_item').find('.c-widget-menu_item-cart').prepend(response.menu);
        },
      });
    }*/

    qte(e) {
        const target = e.currentTarget;
        const form = jQuery(target).parent('form');

        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function( response ){

            },
        });
    }

    /*reset(e) {
      const target = e.currentTarget;
      const form = jQuery(target).parents('.c-tab_item').find('form');
      form[0].reset();

      const btns = form.querySelectorAll('.c-widget-menu_menu-btn');
      btns.forEach(element => element.classList.remove('is-active'));
    }*/

    submit(e) {
        const target = e.currentTarget;
        const form = this.parent('form', target);
        var error = false;
        $(form).find('.commune-error').html('');

        if(!jQuery(form).find('input[name="plats"]').is(":checked")) {
            error = true;
        }

        if(!error) {
            jQuery(form).find('.msg').html('');
            jQuery.ajax({
              url: this.ajaxurl,
              type: 'POST',
              data: jQuery(form).serialize(),
              success: function( response ){
                if(response.error) {
                    $(form).find('.commune-error').html(response.msg);
                    return false;
                }

                $('#'+response.menu_id).remove();
                //$(form).parent('.c-tab_item').find('.c-widget-menu_item-cart').append(response.menu);

                $('.c-widget-menu_cart').find('.c-widget-menu_content').html(response.menu);
                $('.c-widget-menu_cart').addClass('is-open');

                $(form).find('.default').removeClass('is-active');
                $(form)[0].reset();
                $(form).find('input[name="menu_id"]').val(response.new_menu_id);
                
                $(form).parents('.c-widget-menu_body').find('.c-tab_head .c-tab_item.is-active .nb').text(response.count).addClass('is-show');
                if(response.count_all) {
                    $('.count-menu').text('('+response.count_all+')');
                }
              },
            });
        }else {
            jQuery(form).find('.msg').html('Veuillez faire votre choix !');
        }
    }

    update(e) {
        const target = e.currentTarget;
        const id = target.getAttribute('id');
        const qte = jQuery(target).find('input[name="qte"]').val();
        const form = jQuery(target).parents('.c-tab_item').find('form');
        const menu_text = jQuery(target).find('.c-heading').text();

        const items = this.$('update');
        items.forEach(element => element.classList.remove('is-active'));

        target.classList.add('is-active');

        form.find('input[name="menu_id"]').val(id);
        form.find('input[name="quantite"]').val(qte);
        form.find('.main-title').text(menu_text);
    }

    pagination(e) {
        const target = e.currentTarget;
        const id = jQuery(target).data('post');
        let items = document.querySelectorAll('.c-widget-menu_item');
        items.forEach(element => element.classList.remove('is-active'));
        
        
        var elements = document.querySelectorAll('[data-id="'+id+'"]');
        elements.forEach(element => element.classList.add('is-active'));
        this.call('update', false, 'Scroll'); // update Locomotive Scroll
    }

    submitCommune(e) {
        const target = e.currentTarget;
        const form = $(target).parents('form');

        if($('#commune').val() != '') {
            jQuery.ajax({
                url: this.ajaxurl,
                type: 'POST',
                data: jQuery(form).serialize(),
                success: function( response ){
                    // $('.c-widget-menu_item').first().addClass('is-active');
                    if(response.content) {
                        $('.commune-tarifs').html(response.content);
                        $('.commune-tarifs').removeClass('is-hidden');
                        $('.reservation-elements').removeClass('is-hidden');
                        $('.reservations-empty').addClass('is-hidden');
                    }
                },
            });
        }else {
            // jQuery(form).find('.c-form_msg').html('Veuillez sélectionner votre commune !').addClass('error');
        }
    }

    closeCart(e) {
        $('.c-widget-menu_cart').removeClass('is-open');
    }

    checkCartMenu(e) {
        let currentTarget = e.currentTarget;
        let redirectto = $(currentTarget).attr('data-redirectto');
        let $this = this;
        let error = this.$('error');
        
        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {action: 'check_cart_menu'},
            success: function( response ){
                if(!response.check) {
                    $(error).addClass('u-padding-tiny');
                    $(error).html(response.msg);
                    $this.call('scrollTo', error, 'Scroll');
                    return false;
                }else {
                    window.location.href = redirectto;
                }
            },
        });
    }

    deleteCartDay(e) {
        let currentTarget = e.currentTarget;
        let id = $(currentTarget).attr('data-day');
        
        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {action: 'delete_cart_day', id: id},
            success: function( response ){
                location.reload();
            },
        });
    }
}
