import { module } from 'modujs';
import 'select2'; // globally assign select2 fn to $ element
import 'select2/dist/css/select2.css';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            change: {
                selectCommune: 'selectCommune',
            },
        }
        
        this.map = false;
        this.markers = [];
    }

    init() {
        const el = this.$('map');
        const $this = this;
        // var map = false;
        let select2 = $('.select2').select2({
            placeholder: 'Votre commune',
            language: 'fr',
        });

        select2.on("change", function (e) { $this.selectCommune(e) });

        setTimeout(() => {
            if (google !== undefined) {
                this.map = this.initMap($(el))
            }
        }, 2000);
        document.addEventListener("googlemaps_loaded", function() {
            setTimeout(() => {
                if (google !== undefined && this.map === false) {
                  this.map = this.initMap($(el));
                }
            }, 2000);
        });
    }

    selectCommune(e) {
        const markers = this.markers;
        const marker = $('#selectCommune').val();
        
        google.maps.event.trigger(markers[marker], 'click');
    }

    initMap( $el ) {
        const $this = this;
        // Find marker elements within map.
        var $markers = $el.find('.marker');
    
        var styles = [
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#1A1610"
                    },
                    {
                        "weight": "3.06"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#FFFFFF"
                    },
                    {
                        "weight": "3.06"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#F2F2F2"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#F0F0F0"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#FFFFFF"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#5FBCE1"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            }
        ];
    
        // Create gerenic map.
        var mapArgs = {
            zoom        : $el.data('zoom') || 16,
            mapTypeId   : google.maps.MapTypeId.ROADMAP,
            styles: styles,
            maxZoom: 15
        };
        var map = new google.maps.Map( $el[0], mapArgs );
    
        // Add markers.
        map.markers = [];
        $markers.each(function(){
            $this.initMarker( $(this), map );
        });
    
        // Center map based on markers.
        $this.centerMap( map );
    
        // Return map instance.
        return map;
    }

    initMarker( $marker, map ) {
        // Get position from marker.
        var lat = $marker.data('lat');
        var lng = $marker.data('lng');
        var latLng = {
            lat: parseFloat( lat ),
            lng: parseFloat( lng )
        };
        var icon = '';
    
        if($marker.data('icon')) {
            var icon = {
                url : $marker.data('icon'),
                scaledSize: new google.maps.Size(40, 40)
            };
        }
    
        // Create marker instance.
        var marker = new google.maps.Marker({
            position : latLng,
            map: map,
            icon: icon,
            id: $marker.data('id'),
        });
    
        // Append to reference for later use.
        map.markers.push( marker );
    
        // If marker contains HTML, add it to an infoWindow.
        if( $marker.html() ){
    
            // Create info window.
            var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
            });
    
            // Show info window when marker is clicked.
            google.maps.event.addListener(marker, 'click', function() {
                infowindow.open( map, marker );
            });
        }

        this.markers[$marker.data('id')] = marker;
    }

    centerMap( map ) {

        // Create map boundaries from all map markers.
        var bounds = new google.maps.LatLngBounds();
        map.markers.forEach(function( marker ){
            bounds.extend({
                lat: marker.position.lat(),
                lng: marker.position.lng()
            });
        });
    
        // Case: Single marker.
        if( map.markers.length == 1 ){
            map.setCenter( bounds.getCenter() );
    
        // Case: Multiple markers.
        } else{
            map.fitBounds( bounds );
        }
    }
}

/**
 * initMap
 *
 * Renders a Google Map onto the selected jQuery element
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @return  object The map instance.
 */
// function initMap( $el ) {
//     // Find marker elements within map.
//     var $markers = $el.find('.marker');

//     var styles = [
//         {
//             "featureType": "administrative",
//             "elementType": "labels.text.fill",
//             "stylers": [
//                 {
//                     "color": "#1A1610"
//                 },
//                 {
//                     "weight": "3.06"
//                 }
//             ]
//         },
//         {
//             "featureType": "administrative",
//             "elementType": "labels.text.stroke",
//             "stylers": [
//                 {
//                     "color": "#FFFFFF"
//                 },
//                 {
//                     "weight": "3.06"
//                 }
//             ]
//         },
//         {
//             "featureType": "landscape",
//             "elementType": "all",
//             "stylers": [
//                 {
//                     "color": "#F2F2F2"
//                 }
//             ]
//         },
//         {
//             "featureType": "landscape",
//             "elementType": "geometry.fill",
//             "stylers": [
//                 {
//                     "color": "#F0F0F0"
//                 }
//             ]
//         },
//         {
//             "featureType": "poi",
//             "elementType": "all",
//             "stylers": [
//                 {
//                     "visibility": "off"
//                 }
//             ]
//         },
//         {
//             "featureType": "road",
//             "elementType": "all",
//             "stylers": [
//                 {
//                     "saturation": -100
//                 },
//                 {
//                     "lightness": 45
//                 }
//             ]
//         },
//         {
//             "featureType": "road",
//             "elementType": "geometry.fill",
//             "stylers": [
//                 {
//                     "color": "#FFFFFF"
//                 }
//             ]
//         },
//         {
//             "featureType": "road.highway",
//             "elementType": "all",
//             "stylers": [
//                 {
//                     "visibility": "simplified"
//                 }
//             ]
//         },
//         {
//             "featureType": "road.arterial",
//             "elementType": "labels.icon",
//             "stylers": [
//                 {
//                     "visibility": "off"
//                 }
//             ]
//         },
//         {
//             "featureType": "transit",
//             "elementType": "all",
//             "stylers": [
//                 {
//                     "visibility": "off"
//                 }
//             ]
//         },
//         {
//             "featureType": "water",
//             "elementType": "all",
//             "stylers": [
//                 {
//                     "color": "#5FBCE1"
//                 },
//                 {
//                     "visibility": "on"
//                 }
//             ]
//         }
//     ];

//     // Create gerenic map.
//     var mapArgs = {
//         zoom        : $el.data('zoom') || 16,
//         mapTypeId   : google.maps.MapTypeId.ROADMAP,
//         styles: styles,
//         maxZoom: 15
//     };
//     var map = new google.maps.Map( $el[0], mapArgs );

//     // Add markers.
//     map.markers = [];
//     $markers.each(function(){
//         initMarker( $(this), map );
//     });

//     // Center map based on markers.
//     centerMap( map );

//     // Return map instance.
//     return map;
// }

/**
 * initMarker
 *
 * Creates a marker for the given jQuery element and map.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @param   object The map instance.
 * @return  object The marker instance.
 */
// function initMarker( $marker, map ) {
//     // Get position from marker.
//     var lat = $marker.data('lat');
//     var lng = $marker.data('lng');
//     var latLng = {
//         lat: parseFloat( lat ),
//         lng: parseFloat( lng )
//     };
//     var icon = '';

//     if($marker.data('icon')) {
//         var icon = {
//             url : $marker.data('icon'),
//             scaledSize: new google.maps.Size(40, 40)
//         };
//     }

//     // Create marker instance.
//     var marker = new google.maps.Marker({
//         position : latLng,
//         map: map,
//         icon: icon,
//     });

//     // Append to reference for later use.
//     map.markers.push( marker );

//     // If marker contains HTML, add it to an infoWindow.
//     if( $marker.html() ){

//         // Create info window.
//         var infowindow = new google.maps.InfoWindow({
//             content: $marker.html()
//         });

//         // Show info window when marker is clicked.
//         google.maps.event.addListener(marker, 'click', function() {
//             infowindow.open( map, marker );
//         });
//     }
// }

/**
 * centerMap
 *
 * Centers the map showing all markers in view.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   object The map instance.
 * @return  void
 */
// function centerMap( map ) {

//     // Create map boundaries from all map markers.
//     var bounds = new google.maps.LatLngBounds();
//     map.markers.forEach(function( marker ){
//         bounds.extend({
//             lat: marker.position.lat(),
//             lng: marker.position.lng()
//         });
//     });

//     // Case: Single marker.
//     if( map.markers.length == 1 ){
//         map.setCenter( bounds.getCenter() );

//     // Case: Multiple markers.
//     } else{
//         map.fitBounds( bounds );
//     }
// }
